html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: "JetBrains Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfdfdf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}

@font-face {
  font-family: "Glass";
  src: local("Glass"),
   url("./assets/fonts/Glass.ttf") format("truetype");
  }
